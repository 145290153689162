export const homeTexts = {
  title: 'AUTO SERVICIO IZYDOCS',
  subtitle:
    'Desde acá podrás acceder a servicios de la plataforma IZYDOCS de manera fácil. ',
  suplement: 'Para comenzar, solicita al administrador un enlace válido.',
};

export const enrollmentTexts = {
  title: 'BIENVENID@ ',
  subtitle:
    'Es necesario que sigas los siguientes pasos para completar tu registro',
  btnStart: 'Comenzar',
};

export const registrationTexts = {
  title: '¡TODO LISTO!',
  subtitle:
    'Ya estas registrado. Dentro de poco alguien de nuestro equipo se contactará para finalizar el proceso. ',
  suplement: 'Ya puedes cerrar esta ventana, Muchas gracias.',
};

export const surveyTexts = {
  // eslint-disable-next-line max-len
  question: `Del 0-10 donde 0 es completamente insatisfecho y 10 completamente satisfecho, ¿qué tan práctico te resultó el proceso de autocarga de documentos en IzyDoc?`,
  comment: `Compártenos, ¿cuáles son los aspectos que propiciaron tu grado de satisfacción con el proceso?`,
};

export const signInTexts = {
  title: `Bienvenido a IZY TEMPLATE`,
  notificationBox: {
    subtitle: 'Recuerda que necesitas el nombre de tu organización',
    registerButton: 'Registrar nombre',
  },
  errorTitle: '¡Ha ocurrido un Error!',
  submitButton: 'Entrar',
  forgotPassword: `¿Olvidaste tu contraseña? clic aquí`,
};
